export const useGetParameterByName = (name) => {
  const location = window.location;
  let searchParams = new URLSearchParams(location.search);

  let nameValue = searchParams.get(name);

  return nameValue ? nameValue : "";
};

export default useGetParameterByName;
