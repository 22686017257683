import { useWindowWidth } from "./useWindowWidth";

export const useResponsiveDimensions = () => {
  const width = useWindowWidth();

  return {
    isBigTablet: width <= 1200,
    isTablet: width <= 992,
    isMobile: width <= 767,
  };
};
