import React from "react";

import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <div className="PageNotFound">
      <div className="PageNotFound__message">
        <div className="PageNotFound__number">
          4<span>0</span>4
        </div>
        <p className="PageNotFound__label">Ops, Page not found !</p>
      </div>
    </div>
  );
};

export default PageNotFound;
