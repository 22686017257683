import React from "react";
import CloseSvg from "../../../../assets/images/close.svg";

const CartItem = (props) => {
  const { item, handleDelete } = props;
  return (
    <section className="cart-item">
      <div className="cart-item-container">
        <img
          src={CloseSvg}
          onClick={handleDelete}
          className="cart-item-delete"
          width={20}
          height={20}
          alt=""
        />
        <img src={item?.image} alt="" className="cart-item-image" />
        <div className="cart-item-wrapper">
          <div className="cart-item-name">{item?.name}</div>
          <div className="cart-item-price">{item?.price}</div>
        </div>
      </div>
    </section>
  );
};

export default CartItem;
