import React, { useState, useRef, useEffect } from "react";
import { mockProducts } from "../../assets/data/MockProducts";
import cartIcon from "../../assets/images/cart-icon.svg";
import user from "../../assets/images/user.svg";
import Cart from "./Cart/Cart";
import { useOutsideClickDetection } from "../../helpers/useOutsideClickDetection";
import { useLocation } from "react-router";
import jwt_decode from "jwt-decode";
import bouncerLogo from "../../assets/images/bouncer-logo2.svg";
import bouncerIcon from "../../assets/images/bouncer-icon-dark.svg";
import Check from "../../assets/images/check.svg";
import AlertModal from "./AlertModal/AlertModal";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import backButtonLogo from "../../assets/images/back-button.svg";
import { useResponsiveDimensions } from "../../helpers/useResponsiveDimensions";

const Home = () => {
  const [showShadow, setShowShadow] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [decodedToken, setDecodedToken] = useState([]);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems"))
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
  );
  const [alert, setAlert] = useState(false);
  const { t, i18n } = useTranslation();

  const hideCartRef = useRef(null);
  const savedCartItems = JSON.parse(localStorage.getItem("cartItems"))
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [];
  const isMobile = useResponsiveDimensions().isMobile;
  const location = useLocation();
  let params = new URLSearchParams(window.location.search);

  const [languageFromLocal, setLanguageFromLocal] = useState(
    JSON.parse(localStorage.getItem("language")),
  );

  useEffect(() => {
    if (params.has("results_token")) {
      let token = params.get("results_token");
      let decodeToken = jwt_decode(token);
      setDecodedToken(decodeToken);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 5000);
    }
  }, [location.pathname]);

  const hideCart = () => {
    setShowCart(false);
  };

  useOutsideClickDetection(hideCartRef, hideCart);

  window.addEventListener("scroll", () => {
    const lastKnownScrollPosition = window.scrollY;

    if (lastKnownScrollPosition > 40) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }
  });

  return (
    <>
      {showCart && (
        <Cart
          toggle={() => {
            setShowCart(!showCart);
          }}
          cartRef={hideCartRef}
          cartItems={cartItems}
          setCartItems={setCartItems}
          decodedToken={decodedToken}
        />
      )}
      <div className="ecommerce">
        {alert && (
          <AlertModal
            message={
              decodedToken?.verified === false
                ? "Error, please try again"
                : "Success, you have verified your age!"
            }
          />
        )}
        <div
          className={
            showShadow
              ? "ecommerce-header ecommerce-header-shadow"
              : "ecommerce-header"
          }
        >
          <div className="ecommerce-header-container">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                className="BackButton"
                style={{
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                src={backButtonLogo}
                onClick={() => {
                  window.location.href = "https://www.bouncer.global/";
                }}
              />
              <img
                style={{ width: isMobile ? "50px" : "150px" }}
                className="ecommerce-header-logo"
                alt="logo"
                src={isMobile ? bouncerIcon : bouncerLogo}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                <LanguageSelect />
              </div>
              <div className="ecommerce-header-user-wrapper">
                {decodedToken?.verified && (
                  <div className="ecommerce-header-userbg">
                    <img src={Check} alt="" className="check-icon" />
                  </div>
                )}
                <img
                  src={user}
                  alt="user icon"
                  className="ecommerce-header-icon2"
                />
              </div>
              <div className="ecommerce-header-icon-wrapper">
                {savedCartItems?.length > 0 && (
                  <div className="ecommerce-header-dot" />
                )}
                <img
                  src={cartIcon}
                  alt="cart icon"
                  className="ecommerce-header-icon"
                  onClick={() => {
                    setShowCart(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ecommerce-grid">
          {mockProducts.map((item) => (
            <div
              className="ecommerce-product"
              key={item?.id}
              onClick={() => {
                setShowCart(true);
                if (!cartItems?.find((cart) => cart?.id === item?.id)) {
                  let currentItems = [...cartItems, item];
                  setCartItems(currentItems);
                  localStorage.setItem(
                    "cartItems",
                    JSON.stringify(currentItems),
                  );
                }
              }}
            >
              {item?.image && <img
                src={process.env.PUBLIC_URL + item?.image}
                alt=""
                className="ecommerce-product-image"
              />}
              <div className="ecommerce-product-name">{item.name}</div>
              <div className="ecommerce-product-price">{item.price}</div>
              <button className="ecommerce-product-button">
                {t("ecommerce.button")}
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Home;
