export const mockProducts = [
  {
    id: 1,
    image:
      "./products/Botellas-MCFRioja_dbe192e0-47a4-4b4c-832c-4f961b3217e9_480x.png",
    name: "MasterChef Crianza D.O. Ca. Rioja 2019",
    price: "10,89€",
  },
  {
    id: 2,
    image:
      "./products/Botellas-MCFRueda_63e4b8e7-d5ec-43c0-9b46-93a1bf0f8ccc_720x.png",
    name: "MasterChef Verdejo D.O. Rueda 2021",
    price: "7,99€",
  },
  {
    id: 3,
    image:
      "./products/Botellas-MCFRibera-d-Duera_278644bc-3d7d-40b6-aa3c-112ef9bb5e33_550x.png",
    name: "MasterChef Tempranillo D.O. Ribera del Duero 2021",
    price: "11,45€",
  },
  {
    id: 4,
    image:
      "./products/Botellas-MCFToscana-copia-2_550x.png",
    name: "MasterChef D.O. Toro 2020",
    price: "9,35€",
  },
  {
    id: 5,
    image:
      "./products/Botellas-MCFAlbarino_ceed2d4b-38f2-4edd-89b3-8487ab89e507_550x.png",
    name: "MasterChef Albariño D.O. Rías Baixas 2021",
    price: "12,50€",
  },
  {
    id: 6,
    image:
      "./products/Botellas-MCFMadrid_550x.png",
    name: "MasterChef D.O. Vinos de Madrid 2020",
    price: "8,50€",
  },
];
