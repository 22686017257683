import React from "react";
//import CloseSvg from "../../../assets/images/close.svg";

const AlertModal = (props) => {
  const { message, type } = props;
  return (
    <section
      className={
        type === "error"
          ? "alert-modal alert-modal-error"
          : "alert-modal  alert-modal-success"
      }
    >
      {/*   <img
        src={CloseSvg}
        className="alert-modal-delete"
        width={20}
        height={20}
        alt=""
      /> */}
      <div className="alert-modal-text">{message}</div>
    </section>
  );
};

export default AlertModal;
